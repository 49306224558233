<template>
<div class="container">
  <div class="top" id="bloc-0">
    <voice-header></voice-header>
  </div>
  <div class="pricing-container">
    <div class="pricing-alert">
      {{ $t('pricing.new_features_alert') }}
    </div>
    <div class="pricing-header">
       <div class="pricing-title">
         <h1>{{ $t('comm.pricing') }}</h1>
         <p class="subtitle">{{ $t('pricing.pricing_title') }}</p>
       </div>
       
       <div class="billing-toggle-wrapper">
         <div class="billing-toggle">
           <span class="toggle-text">{{ $t('pricing.period_month') }}</span>
           <label class="switch">
             <input type="checkbox" v-model="isYearly">
             <span class="slider round"></span>
           </label>
           <span class="toggle-text">{{ $t('pricing.period_year') }}</span>
           <span class="yearly-discount">{{ $t('pricing.discount_quantity') }}</span>
         </div>
       </div>
    </div>
    <div class="pricing-plans">
      <div v-for="plan in currentPlans" :key="plan.name" class="plan" :class="{ 'popular': plan.isPopular }">
        <div v-if="plan.isPopular" class="popular-tag">{{ $t('pricing.most_popular') }}</div>
        <h2>{{ plan.name }}</h2>
        <p>{{ plan.description }}</p>
        <div>
          <span class="price-number">${{ plan.price }}</span>
          <span class="period">/{{ $t('pricing.period_month_short') }}</span>
	  <div v-if="plan.price > 0.1">
            <p v-if="isYearly" class="billed-yearly" id="starter-billed">{{ $t('pricing.billed_year') }} </p>
          </div>
        </div>
	<div v-if="plan.price > 0.1">
          <form @submit.prevent="createCheckoutSession(plan)">
            <button class="get-started-btn" :class="{ 'popular-btn': plan.isPopular }" type="submit">{{ $t('pricing.buy_btntext') }}</button>
          </form>
        </div>
        <div class="features">
          <ul>
            <li v-for="feature in plan.features" :key="feature">{{ feature }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="signCover" v-if="isSignInShow">
      <div class="signDiv">
        <span class="closex" v-on:click="closePriceSignIn">x</span>
        <div>
          <div class="login_logo">
            <img src="/ssr/img/tiktok_voice_logo.svg" alt="Logo" loading="lazy" height="60px" width="80px" />
            <img src="/ssr/img/tiktok_voice_word.svg" alt="Logo" loading="lazy" height="60px" width="160px" />
          </div>
          <p class="login_h1"> {{ $t('comm.login_desc') }}</p>
        </div>
        <div class="google-button-container">
          <div ref="googlePriceSignInButton" class="custom-google-button"> </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex';
import VoiceHeader from '../components/VoiceHeader.vue';

function setCookie(name,value) {
    var Days = 30;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days*24*60*60*1000);
    document.cookie = name + "="+ escape (value) + "; path=/; domain=tiktokvoice.net; expires=" + exp.toGMTString();
}

export default {
  name: 'pricing',
  data() {
    return {
      isSignInShow: false,
      isYearly: true,
      monthlyPlans: [
        {
          name: this.$i18n.t('pricing.sub_free_name'),
          description: this.$i18n.t('pricing.sub_free_desc'),
          price: '0',
          isPopular: false,
          type_name: 'free_month',
          subscript_type: '0',
          features: this.$i18n.t('pricing.sub_free_content'),
          // features: [
          //   '1000 characters per day limit',
	  //   '10 generations per day',
          // ]
        },
        {
          name: this.$i18n.t('pricing.sub_starter_name'),
          description: this.$i18n.t('pricing.sub_starter_desc'),
          price: '12.99',
          isPopular: true,
          type_name: 'starter_month',
          features: this.$i18n.t('pricing.sub_starter_content'),
          // features: [
	  //   '300000 characters per month limit(approximately 7hours of audio)',
	  //   'Unlimited Downloads',
	  //   '72h email support',
          //   'Priority access to new voices and features'
          // ]
        },
        {
          name: this.$i18n.t('pricing.sub_pro_name'),
          description: this.$i18n.t('pricing.sub_pro_desc'),
          price: '18.99',
          isPopular: false,
          type_name: 'pro_month',
          features: this.$i18n.t('pricing.sub_pro_content'),
          // features: [
	  //   '1000000 characters per month limit (approximately 24hours of audio)',
	  //   'Unlimited Downloads',
	  //   '48h email support',
	  //   'Priority access to new voices and features'
          // ]
        }
      ],
      yearlyPlans: [
        {
          name: this.$i18n.t('pricing.sub_free_name'),
          description: this.$i18n.t('pricing.sub_free_desc'),
          price: '0',
          isPopular: false,
          type_name: 'free_year',
          features: this.$i18n.t('pricing.sub_free_content'),
        },
        {
          name: this.$i18n.t('pricing.sub_starter_name'),
          description: this.$i18n.t('pricing.sub_starter_desc'),
          price: '5.99',
          isPopular: true,
          type_name: 'starter_year',
          features: this.$i18n.t('pricing.sub_starter_content'),
        },
        {
          name: this.$i18n.t('pricing.sub_pro_name'),
          description: this.$i18n.t('pricing.sub_pro_desc'),
          price: '7.99',
          isPopular: false,
          type_name: 'pro_year',
          features: this.$i18n.t('pricing.sub_pro_content'),
        }
      ]
    }
  },
  computed: {
    currentPlans() {
      return this.isYearly ? this.yearlyPlans : this.monthlyPlans;
    },
    ...mapGetters(['isLoggedIn', 'currentUser'])
  },
  head() {
    return {
      'title': this.$i18n.t('pricing.pricing_title'),
      'keywords': this.$i18n.t('txt2voice.keywords'),
      'description': this.$i18n.t('pricing.pricing_desc'),
    }
  },
  components: {
    VoiceHeader
  },
  asyncData: function ({ store, route }) {
    // console.log('home state count:' + store.state.count)
    return store.dispatch("fetchData")
  },
  methods: {
    showPriceSignIn() {
      this.isSignInShow = true;
    },
    closePriceSignIn() {
      this.isSignInShow = false;
    },
    initPriceGoogleSignIn() {
      if (typeof google !== 'undefined' && google.accounts && google.accounts.id) {
        try {
          google.accounts.id.initialize({
            client_id: '304803644975-lmsl5t2frh2vniu0f9j9ua9pbso86fg2.apps.googleusercontent.com',
            callback: this.handleCredentialResponse,
            ux_mode: 'popup',
          });
          google.accounts.id.renderButton(
            this.$refs.googlePriceSignInButton,
            { theme: 'filled_blue', size: 'large', text: 'signin_with',  locale: 'en' }
          );
        } catch (error) {
          console.error("Error initializing Google Sign-In:", error)
        }
      } else {
        console.error("Google Sign-In SDK not loaded!")
      }
    },
    handleCredentialResponse(response) {
      // Handle the sign-in response here
      console.log("Received response:", response)
      if (response.credential) {
        console.log("Successfully logged in with Google")
        this.sendTokenToBackend(response.credential)
      }
      this.closePriceSignIn()
    },
    async sendTokenToBackend(token) {
      var host = this.$i18n.t('host')
      var uri = host + '/lapi/auth/google'
      try {
        const response = await fetch(uri, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify( {token} )
        });

        if (response.ok) {
          const data = await response.json()
          // 处理成功的后认证
          console.log("Backend authentication successful:", data);
          if (data.ret == 0) {
            // 处理成功的后端认证
            console.log("Backend authentication successful:", data);
            // 提取相关的用户数据
            const userData = {
              'email': data.email,
              'name': data.full_name,
              'picture': data.picture,
            };
            // 将用户数据存储在 Vuex store 中
            this.$store.dispatch('loginUser', userData)
            // 存储用户数据到cookie
            setCookie('userData', JSON.stringify(userData))
            this.$emit('login-success', userData)
	    // this.createCheckoutSession()
          } else {
            console.error("reqid:" + data.reqid + ", ret:" + data.ret + ", msg:" + data.msg)
            this.$store.dispatch('logoutUser');
            document.cookie = "userData=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
            this.$emit('login-error', 'Backend authentication failed');
          }
        } else {
          console.erro('Backend authentication failed!')
          // 处理认证失败
          this.$store.dispatch('logoutUser');
          document.cookie = "userData=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
          this.$emit('login-error', 'Backend authentication failed');
        }
      } catch (error) {
        console.error('Error sending token to backend:', error)
        this.$store.dispatch('logoutUser');
        document.cookie = "userData=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
        this.$emit('login-error', 'Network error during authentication');
      }
    },
    async createCheckoutSession(plan) {
      if (!this.isLoggedIn) {
        console.log("create checkout session, login")
        this.showPriceSignIn()
	return 
      }
      var host = this.$i18n.t('host')
      var uri = host + '/lapi/cpaysess'
      var email = ''
      if (this.$store.state.user) {
        email = this.currentUser.email
        console.log("create checkout session, email 11:" + email)
      }
      try {
        const formData = new FormData() 
        formData.append('type_name', plan.type_name)
        formData.append('email', email)
        var success_url = '/' + this.$store.state.lang + '/pay/success'
        formData.append('success_url', success_url)
        var cancel_url = '/' + this.$store.state.lang + '/pay/cancel'
	formData.append('cancel_url', cancel_url)
        const headers = {
	  'Content-Type': 'multipart/form-data'
        }
        console.log('start post!')
        const res = await axios.post(uri, formData, headers)
	// 处理响应, 例如重定向到Stripe Checkout页面
        console.log('respose ret:', res.data.ret)
        console.log('respose url:', res.data.url)
	if (res.data.ret === 0){
          window.location.href = res.data.url
	} else {
	  console.log('msg:' + res.data.msg)
	}
      } catch (error) {
        console.error('Error creating checkout session:', error)
      }
    }
  },
  mounted() {
    console.log('pricing, i18n locale:', this.$i18n.locale)
    this.$nextTick(() => {
      this.initPriceGoogleSignIn();
    });
  },
  updated() {
    this.$nextTick(() => {
      this.initPriceGoogleSignIn();
    });
  }
}
</script>

<style scoped>
.pricing-container {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
}

@media (max-width: 920px) {
  .pricing-container {
    padding: 10px 20px;
  }
}

.pricing-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 40px;
}

.pricing-title {
  flex: 1;
}

h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 16px;
  color: #6c757d;
}

.billing-toggle-wrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: -8px;
}

.billing-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  height: 38px;
}

.toggle-text {
  font-weight: bold;
  font-size: 14px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #000;
}

input:checked + .slider:before {
  transform: translateX(32px);
}

.yearly-discount {
  color: #28a745;
  font-weight: bold;
  background-color: #e6f7e6;
  padding: 3px 8px 3px 12px;
  border-radius: 12px;
  font-size: 12px;
}

.pricing-plans {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
}

.plan {
  flex: 1 1 250px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 30px;
  position: relative;
  max-width: 300px;
  margin-bottom: 30px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.popular {
  border: 2px solid #000;
}

.popular-tag {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 5px;
  font-weight: bold;
}

.plan h2 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-size: 28px;
}

.plan p {
  margin-top: 0;
  margin-bottom: 20px;
  color: #6c757d;
}

.price-number {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 20px;
}

.billed-yearly {
  font-size: 0.5em;
  color: #6c757d;
}

.period {
  font-size: 16px;
  color: #666;
}

.get-started-btn {
  display: block;
  width: 100%;
  background: linear-gradient(145deg, #2c2c2c, #1a1a1a);
  color: white;
  text-decoration: none;
  padding: 15px 0;
  border-radius: 8px;
  font-weight: bold;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  margin: 20px 0;
}

.get-started-btn:hover {
  background: linear-gradient(145deg, #1a1a1a, #2c2c2c);
  box-shadow: 0 6px 8px rgba(0,0,0,0.15);
  transform: translateY(-2px);
}

.popular-btn {
  background-color: #000;
  color: #fff;
}

.features {
  background-color: white;
  padding: 0;
  margin-top: 20px;
}

.features ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.features li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 25px;
}

.features li::before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #28a745;
}

@media (max-width: 1000px) {
  .pricing-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
  }

  h1 {
    font-size: 32px;
  }

  .subtitle {
    font-size: 14px;
  }

  .billing-toggle {
    align-self: center;
  }

  .pricing-plans {
    flex-direction: column;
    gap: 40px;
  }

  .plan {
    max-width: 100%;
    width: 100%;
    margin-bottom: 0;
  }

}

/* 移动端样式 */
@media (max-width: 768px) {
  .pricing-header {
    flex-direction: column;
    align-items: center;
  }

  .pricing-title {
    text-align: center;
    margin-bottom: 20px;
  }

  .billing-toggle-wrapper {
    width: 100%;
    justify-content: center;
  }

  .billing-toggle {
    justify-content: center;
  }
}
</style>
